import clsx from 'clsx';
import * as React from 'react';
import styles from './LazyImage.module.css';

export interface Props extends React.ComponentPropsWithoutRef<'img'> {
  rootMargin?: string;
  threshold?: number;
}

export function LazyImage({
  rootMargin = '50px',
  threshold,
  src,
  srcSet,
  className,
  ...restProps
}: Props): React.ReactElement {
  const observer = React.useRef<IntersectionObserver>(undefined);
  const listenerCallbacks = React.useRef<Map<HTMLDivElement, () => void>>(
    new Map()
  );
  const imageRef = React.useRef<HTMLImageElement>(null);
  const [isInView, setIsInView] = React.useState<boolean>(false);
  const [isLoaded, setIsLoaded] = React.useState<boolean>(false);

  function handleIntersections(entries: IntersectionObserverEntry[]): void {
    entries.forEach((entry) => {
      const image = entry.target as HTMLImageElement;
      if (listenerCallbacks.current && listenerCallbacks.current.has(image)) {
        const callback = listenerCallbacks.current.get(image);
        if (callback && (entry.isIntersecting || entry.intersectionRatio > 0)) {
          observer.current?.unobserve(entry.target);
          listenerCallbacks.current.delete(image);
          callback();
        }
      }
    });
  }

  function getIntersectionObserver(): React.MutableRefObject<
    IntersectionObserver | undefined
  > {
    if (observer.current === undefined) {
      observer.current = new IntersectionObserver(handleIntersections, {
        rootMargin,
        threshold,
      });
    }
    return observer;
  }

  function useIntersection(
    element: React.RefObject<HTMLImageElement | null>,
    callback: () => void
  ): void {
    React.useEffect(() => {
      const target = element.current;
      if (target) {
        const observer = getIntersectionObserver();
        listenerCallbacks.current.set(target, callback);
        observer.current?.observe(target);
      }
      return () => {
        if (target) {
          listenerCallbacks.current.delete(target);
          observer.current?.unobserve(target);
        }
      };
    }, []);
  }

  function handleImageLoad() {
    setIsLoaded(true);
  }

  useIntersection(imageRef, () => {
    setIsInView(true);
  });

  React.useEffect(() => {
    const img = imageRef.current;

    if (img) {
      img.addEventListener('load', handleImageLoad);
    }

    return () => {
      if (img) {
        img.removeEventListener('load', handleImageLoad);
      }
    };
  }, []);

  return (
    <img
      ref={imageRef}
      src={isInView ? src : undefined}
      srcSet={isInView ? srcSet : undefined}
      className={clsx(className, styles.image, {
        [styles.loaded]: isLoaded,
      })}
      {...restProps}
    />
  );
}
